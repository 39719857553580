import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const category_url = '/?category=' + post.frontmatter.category
  const blogimage = post.frontmatter.blogimage.childImageSharp.gatsbyImageData.images.fallback.src
  //console.log(post.frontmatter.blogimage.childImageSharp.gatsbyImageData)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        blogimg={blogimage}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8614409317427041"
            crossOrigin="anonymous"></script>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p><time itemProp="dateCreated datePublished">{post.frontmatter.date}</time></p>
          <ul className="breadcrumb__list" itemScope itemType="https://schema.org/BreadcrumbList">
            <li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <a itemProp="item" href="/"><span itemProp="name">TOPページ</span></a>
              <meta itemProp="position" content="1" />
            </li>
             ＞ 
            <li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
             <a itemProp="item" href={category_url}><span itemProp="name">{post.frontmatter.category}</span></a>
             <meta itemProp="position" content="2" />
            </li>
             ＞ 
            <li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <span itemProp="name">{post.frontmatter.title}</span>
              <meta itemProp="position" content="3" />
            </li>
          </ul>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        カテゴリ:<a href={category_url}>{post.frontmatter.category}</a>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
        description
        category
        blogimage {
          childImageSharp  { gatsbyImageData(width:1200,layout: FIXED) }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
